import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

export interface PopupFactoryInterface {
  popupComponent: any;
  popupData?: any;
  title?: string;
  subscribers?: string[];
  popupBodyClass?: string;
  hideIcon?: boolean;
  hideCloseBtn?: boolean;
  preventBgClick?: boolean;
  isHeightFull?: boolean;
  isReadLatterConfirm?: boolean;
  uid?: number;
}

@Injectable()
export class PopupFactoryService {
  public popupOutput$: Subject<any> = new Subject();
  public scrollSuppress: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public closePopup$: EventEmitter<void> = new EventEmitter();
  private popupSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public popup$: Observable<any> = this.popupSubject.pipe(filter(form => form !== null));

  createPopup({
    popupComponent,
    isReadLatterConfirm,
    popupData,
    title,
    subscribers,
    popupBodyClass,
    hideIcon,
    hideCloseBtn,
    preventBgClick,
    isHeightFull,
  }: PopupFactoryInterface): Observable<any> {
    const uid = Date.now(); // generate "random" id, date should be fine
    return new Observable((observer: Observer<any>): void => {
      this.popupSubject.next({
        popupComponent,
        popupData,
        isReadLatterConfirm,
        title,
        subscribers,
        popupBodyClass,
        hideIcon,
        hideCloseBtn,
        preventBgClick,
        isHeightFull,
        uid,
      });
      this.popupOutput$
        .pipe(
          filter(data => data.uid === uid),
          take(1),
        )
        .subscribe(data => observer.next(data));
    }).pipe(take(1));
  }

  closePopup(): void {
    this.closePopup$.emit();
  }
}
